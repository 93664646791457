// const leftNavSystemAdmin = [
//   {
//     order: 1,
//     name: "Dashboard",
//     expanded: false,
//     route: "/dashboard",
//     icon: <i className="bx bx-grid-alt"></i>,
//   },
//   {
//     order: 2,
//     name: "Kitchen Management",
//     route: "/kitchens",
//     icon: <i className="bx bx-briefcase"></i>,
//   },
//   {
//     order: 3,
//     name: "POD Management",
//     expanded: false,
//     route: "/pods",
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 4,
//     name: "School Management",
//     route: "/schools",
//     expanded: false,
//     icon: <i className="mdi mdi-school"></i>,
//   },
//   {
//     order: 5,
//     name: "Food Management",
//     route: "/foods",
//     expanded: false,
//     icon: <i className="mdi mdi-food"></i>,
//   },
//   {
//     order: 6,
//     name: "User Management",
//     route: "/users",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 7,
//     name: "School Chain",
//     route: "/schoolChains",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 8,
//     name: "Support Team",
//     route: "/support",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 9,
//     name: "Settings",
//     route: "/settings",
//     expanded: false,
//     icon: <i className="bx bx-cog"></i>,
//   },
//   {
//     order: 10,
//     name: "Orders",
//     route: "/orders",
//     expanded: false,
//     icon: <i className="mdi mdi-food"></i>,
//   },
//   {
//     order: 11,
//     name: "Customer Details",
//     route: "/customerDetails",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 12,
//     name: "School Order Report",
//     route: "/schoolOrderReports",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 13,
//     name: "Cashback Transactions",
//     route: "/transactionTable",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 14,
//     name: "Plans",
//     route: "/plans",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 15,

//     name: "Cancel Bulk Order",
//     route: "/cancelBulkOrder",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 16,
//     name: "Missing Box Report",
//     route: "/missingBoxReport",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 17,
//     name: "Mark order dispatched transaction",
//     route: "/orderDispatchedTransactions",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 18,
//     name: "Mark order delivered transaction",
//     route: "/orderDeliveredTransactions",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 19,
//     name: "Scan Timing Report",
//     route: "/scanTimings",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 20,
//     name: "Customer Feedback",
//     route: "/Feedback",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
//   {
//     order: 21,
//     name: "Overlapping Subscription",
//     route: "/Overlapping-Subscription",
//     expanded: false,
//     icon: <i className="mdi mdi-bookshelf"></i>,
//   },
// ];

const leftNavSystemAdmin = [
  {
    // order: 1,
    name: "Management",
    expanded: true,
    children: [
      {
        order: 1,
        name: "Kitchen Management",
        route: "/kitchens",
        icon: <i className="bx bx-briefcase"></i>,
      },
      {
        order: 2,
        name: "POD Management",
        expanded: false,
        route: "/pods",
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      // {
      //   order: 3,
      //   name: "Menu Management",
      //   expanded: false,
      //   route: "/menus",
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
      {
        order: 4,
        name: "School Management",
        route: "/schools",
        expanded: false,
        icon: <i className="mdi mdi-school"></i>,
      },
      {
        order: 5,
        name: "Food Management",
        route: "/foods",
        expanded: false,
        icon: <i className="mdi mdi-food"></i>,
      },
      {
        order: 6,
        name: "User Management",
        route: "/users",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      
      {
        order: 7,
        name: "School Chain",
        route: "/schoolChains",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 8,
        name: "Settings",
        route: "/settings",
        expanded: false,
        icon: <i className="bx bx-cog"></i>,
      },
      // {
      //   order: 7,
      //   name: "Referral Setting",
      //   route: "/referral-setting",
      //   expanded: false,
      //   icon: <i className="bx bx-cog"></i>,
      // },
      {
        order: 9,
        name: "Plans",
        route: "/plans",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 10,
        name: "Plan to School Mapping",
        route: "/plans-school-map",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 11,
        name: "Cancel Bulk Order",
        route: "/cancelBulkOrder",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 11,
        name: "Menu Cape",
        route: "/menu-caped",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },

      {
        order: 11,
        name: "Subitem",
        route: "/Subitem",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 12,
        name: "Invoices",
        route: "/Invoices",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      
      
      // {
      //   order: 10,
      //   name: "Approve Refund Request",
      //   route: "/refund-requests",
      //   expanded: false,
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
    ],
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    // order: 1,
    name: "Transaction",
    expanded: true,
    children: [
      // {
      //   order: 1,
      //   name: "Support Team",
      //   route: "/support",
      //   expanded: false,
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
      // {
      //   order: 2,
      //   name: "Cashback Transactions",
      //   route: "/transactionTable",
      //   expanded: false,
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
      {
        order: 3,
        name: "Mark order delivered transaction",
        route: "/orderDeliveredTransactions",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 4,
        name: "Mark order dispatched transaction",
        route: "/orderDispatchedTransactions",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 5,
        name: "Overlapping Subscription",
        route: "/Overlapping-Subscription",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      // {
      //   order: 6,
      //   name: "Refund Transactions",
      //   route: "/refund-transactions",
      //   expanded: false,
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
    ],
    icon: <i className="bx bx-grid-alt"></i>,
  },
  {
    // order: 1,
    name: "Dashboard",
    expanded: true,
    children: [
      {
        order: 1,
        name: "Menu Status",
        expanded: false,
        route: "/menus",
        // route: "/dashboard",
        icon: <i className="bx bx-grid-alt"></i>,
      },
      {
        order: 2,
        name: "Customer Details",
        route: "/customerDetails",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 3,
        name: " Loyal Customer",
        route: "/loyal-customer",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      
      {
        order: 4,
        name: "Orders",
        route: "/orders",
        expanded: false,
        icon: <i className="mdi mdi-food"></i>,
      },
      // {
      //   order: 4,
      //   name: "School Order Report",
      //   route: "/schoolOrderReports",
      //   expanded: false,
      //   icon: <i className="mdi mdi-bookshelf"></i>,
      // },
      {
        order: 5,
        name: "Missing Box Report",
        route: "/missingBoxReport",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 6,
        name: "Customer Feedback",
        route: "/Feedback",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
      {
        order: 7,
        name: "Scan Timing Report",
        route: "/scanTimings",
        expanded: false,
        icon: <i className="mdi mdi-bookshelf"></i>,
      },
    ],
    icon: <i className="bx bx-grid-alt"></i>,
  },
];

export default leftNavSystemAdmin;
